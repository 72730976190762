<template>
  <teleport to="body">
    <div el="modal-center" v-if="show">
      <div el="list md">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  name: "Modal",
  //   props:['show'],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>
