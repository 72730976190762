<template>
    <div class="float-menu" v-show="show" :style="style" tabindex="0">
        <slot></slot>
    </div>
</template>
<script>
export default {
  name: 'FloatMenu',
    props: {
        display: Boolean, // prop detect if we should show context menu
    },
  data(){
      return {
        right: 32, // right position
        bottom: 32, // bottom position
        show: true, // affect display of context menu
      }
  },
  methods: {
      // closes context menu 
        close() {
            this.show = false;
            // this.left = 0;
            // this.top = 0;
        },
        // open(evt) {
        open() {
            // updates position of context menu 
            // this.left = evt.pageX || evt.clientX;
            // this.top = evt.pageY || evt.clientY;
            // make element focused 
            // @ts-ignore
            // this.nextTick(() => this.$el.focus());
            this.show = true;
        },
  },
  computed: {
        // get position of context menu
        style() {
            return {
                bottom: this.bottom + 'px',
                right: this.right + 'px',
            };
        },
    },
}
</script>
<style>
.float-menu {
    position: fixed;
    background: #fff;
    min-height:20px;
    z-index: 10;
    width:250px;
    outline: none;
    box-shadow: 0 0 0 3px rgb(0 0 0 / 80%);
    cursor: pointer;
    border-radius:5px;
    padding:8px 0;
}
.float-menu .material-icons{
    font-size:20px;
}
.float-menu .option{
    padding:8px 16px;
    display:grid;
    grid-auto-flow: column;
    grid-gap:8px;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    cursor: pointer !important;
}
.float-menu .option:hover{
    background-color:#0066ff10;
    color:#0068ff;
}
</style>