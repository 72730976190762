<template>
  <List el="md" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Admin Files</Text>
    </List>
    <Files />
  </List>
</template>
<script>
// import router from "@/router";
// let filter = require("lodash/filter");
// let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");
import Files from '../components/Files.vue'

export default {
  components: {
    Files
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
  },
};
</script>
<style lang="scss" scoped></style>
