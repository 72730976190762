<template>
  <Modal ref="modal">
    <div el="list-column-between">
      <h3 el>{{ modal.section }}</h3>
      <span class="material-icons" @click="$refs.modal.toggle()" el="icon"
        >close</span
      >
    </div>
    <div el="list md" v-if="modal.section == 'New Folder'">
      <input type="text" placeholder="Folder name" v-model="modal.folder" class="modalInput" @keyup.enter="createFolder(modal.folder); $refs.modal.toggle();" />
      <div el="list-column-between">
        <div></div>
        <div>
          <div
            el="btn"
            @click="
              createFolder(modal.folder);
              $refs.modal.toggle();
            "
          >
            Create Folder
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <ContextMenu :display="showContextMenu" ref="menu" @click="closeContextMenu">
    <div v-if="contextMenu.type == 'file'">
      <div
        @click="previewFile(contextMenu.data.fullPath)"
        class="option"
        v-if="contextMenu.data != null && storage.selected.length == 1"
      >
        <span class="material-icons">visibility</span>
        <Text el="bold">Preview</Text>
      </div>
      <div @click="downloadSelected" class="option">
        <span class="material-icons">save_alt</span>
        <Text el="bold">Download</Text>
      </div>
      <div
        @click="copyFileLink(contextMenu.data.fullPath)"
        class="option"
        v-if="$store.getters.isAdmin && storage.selected.length == 1"
      >
        <span class="material-icons">link</span>
        <Text el="bold">Copy Link</Text>
      </div>
      <div @click="deleteSelected" class="option" v-if="$store.getters.isAdmin">
        <span class="material-icons">delete</span>
        <Text el="bold">Delete</Text>
      </div>
    </div>
    <div v-if="contextMenu.type == 'folder' && $store.getters.isAdmin">
      <div @click="deleteSelected" class="option">
        <span class="material-icons">delete</span>
        <Text el="bold">Delete</Text>
      </div>
    </div>
    <div v-if="contextMenu.type == 'nothing' && $store.getters.isAdmin">
      <div @click="toggleModal('New Folder')" class="option">
        <span class="material-icons">folder</span>
        <Text el="bold">New Folder</Text>
      </div>
      <label for="file-selector" class="option">
          <span class="material-icons">note_add</span>
          <Text el="bold">Upload</Text>
      </label>
      
    </div>
  </ContextMenu>
  <FloatMenu v-if="storage.uploads.length > 0" ref="float">
    <div v-if="$store.getters.isAdmin">
      <List el="xxsm">
        <List el="column-between xsm p-xsm">
          <h3 el>Uploads - {{totalUploadProgress}}%</h3>
          <!-- <span class="material-icons" @click="$refs.float.close()" el="icon">close</span> -->
          <Icon name="close" @click="$refs.float.close()" v-if="totalUploadProgress === 100" />
        </List>
        <hr el>
        <div style="overflow:auto;max-height:200px;">
          <div class="option" v-for="item in storage.uploads" :key="item">
            <!-- <span class="material-icons">folder</span> -->
            <Text el="bold" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :title="item.name">{{storage.uploadProgress[item.name]}}% - {{item.name}}</Text>
          </div>
        </div>
        
      </List>
      
    </div>
  </FloatMenu>
  <div
    el="list md"
    @click="closeContextMenu"
    @drop="dragFile"
    @contextmenu.prevent="openContextMenu($event, 'nothing')"
    @dragover.prevent 
    @drop.prevent
    style="min-height: calc(100vh - 220px)"
  >
    <div v-show="$store.getters.isAdmin" el="list-column xsm">
      <div el="btn" @click="toggleModal('New Folder')">New Folder</div>
      <label for="file-selector">
        <Icon name="note_add" style="font-size:40px;" />
      </label>
      <input type="file" id="file-selector" multiple style="display:none;" />
    </div>
    <div el="list-column-between">
      <div class="breadcrumbs">
        <div
          v-for="(item, index) in pathArray"
          :key="item"
          @click="visitPath(index + 1)"
        >
          {{ item }}
        </div>
      </div>
      <div el="list-column xsm" class="v-center" v-if="numFilesSelected > 0">
        <div el="link">Deselect ({{ numFilesSelected }})</div>
        <div @click="downloadSelected" el="icon">
          <span class="material-icons">save_alt</span>
          <!-- <div>Download</div> -->
        </div>
      </div>
    </div>
    <div class="folders" v-if="storage.folders.length > 0">
      <div
        v-for="item in storage.folders"
        :key="item"
        @click.stop="listFiles(item.fullPath)"
        :class="{ active: storage.selected.includes(item.fullPath + '/.dir') }"
        @contextmenu.prevent.stop="openContextMenu($event, 'folder', item)"
        :title="item.name"
      >
        <span class="material-icons">folder</span>
        {{ item.name }}
      </div>
    </div>
    <Text el="bold" v-show="storage.files.length > 0">Files</Text>
    <div id="files" class="files" :keyup="deleteSelected">
      <div
        v-for="item in storage.files"
        :key="item"
        @click.stop="selectFile(item.fullPath)"
        :class="{ active: storage.selected.includes(item.fullPath) }"
        @contextmenu.prevent.stop="openContextMenu($event, 'file', item)"
        :title="item.name"
      >
        {{ item.name }}
      </div>
    </div>

  <!-- <ul>
    <li v-for="item in storage.uploads" :key="item">
      {{item.name}}
    </li>
  </ul> -->



  </div>
</template>
<script>
// import _ from 'lodash'
import ContextMenu from "@/components/ContextMenu.vue";
import FloatMenu from "@/components/FloatMenu.vue";
import Modal from "@/components/Modal.vue";
import Icon from "@/components/btn/Icon.vue";
import { reactive } from 'vue'
// import _ from "lodash";
import { 
  getStorage, 
  ref, 
  listAll,
  uploadBytes,
  deleteObject,
  getDownloadURL,
  uploadBytesResumable  
 } from "firebase/storage";

const storage = getStorage();

export default {
  name: "Files",
  //   props:['title'],
  components: {
    ContextMenu,
    FloatMenu,
    Modal,
    Icon
  },
  data() {
    return {
      section: this.$store.state.main.layout.section,
      showContextMenu: false,
      contextMenu: {
        type: "file",
        data: null,
      },
      modal: {
        folder: "",
        section: "New Folder",
      },
      storage: {
        timers: {
          upload: null,
          delete: null,
        },
        currentPath: "",
        ref: "shared-files",
        selected: [],
        search: "",
        folders: [],
        files: [],
        uploads: [],
        uploadProgress:reactive({}),
      },
    };
  },
  methods: {
    toggleModal(section) {
      this.modal.section == section;
      this.$refs.modal.toggle();
    },
    openContextMenu(e, type, item = null) {
      this.contextMenu.type = type;
      switch (type) {
        case "folder":
          this.storage.selected = [];
          this.selectFolder(item.fullPath);
          break;
        case "file":
          this.selectFile(item.fullPath, true);
          break;
        case "nothing":
          
          break;
      }
      this.contextMenu.data = item;
      this.$refs.menu.open(e);
    },
    closeContextMenu() {
      this.$refs.menu.close();
      this.storage.selected = [];
    },
    createFolder(name) {
      let x = this;
      var path = this.storage.currentPath + "/" + name + "/.dir";
      const storageRef = ref(storage, path);

      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, '').then(() => {
        x.listFiles(x.storage.currentPath);
        // console.log('Uploaded a blob or file!');
      });
    },
    visitPath(index) {
      var path = this.pathArray.slice(0, index).join("/");
      this.listFiles(path);
    },
    listFiles(path = null) {
      var x = this;
      if (path == null) {
        path = x.storage.ref;
      }
      x.closeContextMenu();
      x.section.collapse = true;
      x.storage.currentPath = path;
      x.storage.selected = [];
      // Create a reference under which you want to list
      // var listRef = storage.ref().child(path);
      // Create a reference under which you want to list
      const listRef = ref(storage, path);
      // var listRef = storageRef.child('shared-files/uid');
      // Reset list
      
      // Find all the prefixes and items.
      listAll(listRef)
        .then((res) => {
          x.resetList();
          res.prefixes.forEach((folderRef) => {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
            // console.log('folderRef', folderRef);
            x.storage.folders.push(folderRef);
          });
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            // console.log('itemRef', itemRef);
            if (itemRef.name != ".dir") {
              x.storage.files.push(itemRef);
            }
          });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    },
    resetList() {
      this.storage.folders = [];
      this.storage.files = [];
    },
    selectFile(path, val = false) {
      var x = this;
      var i = x.storage.selected.indexOf(path);
      if (i >= 0) {
        if (x.storage.selected.length == 1) {
          x.previewFile(path);
        } else if (!val) {
          x.storage.selected.splice(i, 1);
        }
      } else {
        x.storage.selected.push(path);
      }
    },
    selectFolder(path) {
      var x = this;
      path = path + "/.dir";
      var i = x.storage.selected.indexOf(path);
      if (i >= 0) {
        x.storage.selected.splice(i, 1);
      } else {
        x.storage.selected.push(path);
      }
    },
    async deleteFile(path) {
      var x = this;
      const storageRef = ref(storage, path);

      // Delete the file
      deleteObject(storageRef).then(() => {
        // File deleted successfully
        console.log('deleted "' + path + '"');
        x.listFiles(x.storage.currentPath);
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });
    },
    deleteSelected() {
      var x = this;
      x.storage.selected.forEach((item) => {
        x.deleteFile(item);
      });
    },
    downloadSelected() {
      var x = this;
      x.storage.selected.forEach((item) => {
        x.downloadFile(item);
      });
    },
    downloadFile(path) {
      let x = this;
      const storageRef = ref(storage, path);

      getDownloadURL(storageRef)
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = () => {
            const blob = xhr.response;
            x.downloadBlob(blob, storageRef.name);
          };
          xhr.open('GET', url);
          xhr.send();

          // Or inserted into an <img> element
          // const img = document.getElementById('myimg');
          // img.setAttribute('src', url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    },
    downloadBlob(blob, name) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob)
        return window.navigator.msSaveOrOpenBlob(blob);

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = data;
      link.download = name;

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
    async uploadFile(file, name) {
      var x = this;
      var path = this.storage.currentPath;
      

      const storageRef = ref(storage, path + "/" + name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      x.storage.uploadProgress[name] = 0;

      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          x.storage.uploadProgress[name] = progress;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        }, 
        () => {
          // Handle successful uploads on complete
          // clearTimeout(x.storage.timers.upload);
          // x.storage.timers.upload = setTimeout(() => {
          //   x.listFiles(path);
          // }, 1000);
          x.listFiles(path);
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
          });
        }
      );


    },
    dragFile(e) {
        this.storage.uploadProgress = {};
        this.storage.uploads = e.dataTransfer.files;
        for (const file of this.storage.uploads) {
          this.uploadFile(file, file.name);
        }
    },
    previewFile(path) {
      let x = this;
      const storageRef = ref(storage, path);

      getDownloadURL(storageRef)
        .then((url) => {
          console.log(url);
          // console.log('NEW PATH'+encodeURIComponent(path));
          let newPath = encodeURIComponent(path);
          x.section.collapse = false;
          x.$router.push({ path: `/dashboard/files/view/${newPath}`});
          // params: { path: path } 
        // });
          // window.open(url);
          
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    },
    copyString(copyText) {
      // variable content to be copied
      let input = document.createElement("input");
      input.setAttribute("type", "text");
      input.value = copyText;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
    copyFileLink(path) {
      var x = this;
      if (path) {
        const storageRef = ref(storage, path);
        getDownloadURL(storageRef).then((url) => {
            x.copyString(url);
          })
          .catch((error) => {
            alert(error);
          });
      }
    },
  },
  watch: {
    'section.collapse':function(val){
      if(val == true){
        // alert('closed');
        this.closeContextMenu();
      }
    }
    //   'users.filters.approvedAccount'(){
    //       this.getUserData();
    //   }
  },
  computed: {
    pathArray() {
      return this.storage.currentPath.split("/");
    },
    numFilesSelected() {
      return Object.keys(this.storage.selected).length;
    },
    userProfile() {
      return this.$store.state.userProfile;
    },
    totalUploadProgress(){
      let total = 0;
      let uploadsLen = this.storage.uploads.length;
      for(let key in this.storage.uploadProgress){
        total+=this.storage.uploadProgress[key];
      }
      return Math.round(total / uploadsLen);
    }
  },
  mounted() {
    var x = this;
    //   Setup
    this.listFiles();
    //   x.$set(x.storage, 'selected', new Set());
    //   this.$set(x.storage, 'selected', 'hi');
    // Handle files
    const fileSelector = document.getElementById("file-selector");
    fileSelector.addEventListener("change", (event) => {
      const fileList = event.target.files;
      console.log(fileList);
      this.storage.uploadProgress = {};
      this.storage.uploads = fileList;
      for (const file of fileList) {
        x.uploadFile(file, file.name);
      }
    });

    // Handle key press
    // const files = document.getElementById("files");
    window.addEventListener("keyup", (event) => {
      switch (event.key) {
        case "Delete":
          x.deleteSelected();
          break;
      }
      // console.log(event.key);
    });
  },
};
</script>
<style lang="scss">
.folders,
.files {
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 16px;
}
.folders > * {
  display: grid;
  grid-gap: 8px;
  align-items: center;
  grid-auto-flow: column;
  justify-content: flex-start;
  padding: 10px 16px;
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
  border: 2px solid transparent;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.folders .material-icons {
  color: inherit;
}
.folders > *:hover {
  border: 2px solid #555;
  color: #555;
}
.files > * {
  padding: 10px 16px;
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 7%);
  border: 2px solid transparent;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.files > *:hover {
  border: 2px solid #555;
}
.files .active,
.folders .active {
  border: 2px solid #0068ff;
}
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}
.breadcrumbs > * {
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #d0e6f5;
  color: #2facee;
  margin: 4px;
  font-size: 16px;
  cursor: pointer;
}
</style>
<style lang="scss">
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
[el="tabs"] {
  user-select: none;
}
.close {
  color: var(--color-40);
  -webkit-text-fill-color: currentColor;
  cursor: pointer;
  transition: 0.2s;
}
.close:hover {
  color: var(--color-sky);
}
.v-center {
  align-items: center;
}

.thumbnail {
  width: 100%;
  height: 90px;
  object-fit: contain;
  opacity: 0.07;
  object-position: center;
}
.status {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  position: absolute;
  transform: translateY(-50%) translateX(-100%);
}
.text-right {
  text-align: right;
}
.modalInput {
  border: 0;
  padding: 0 14px;
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  background-color: hsl(0deg 0% 0% / 7%);
}
</style>
